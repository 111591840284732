body {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 160rem;
  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Helvetica,    Arial, sans-serif;
}
.Logo {
  margin: 2rem 0;
  width: 100%;
  color: #0f2e53;
  font-size: 1.8rem;
  font-weight: 700;
}
.Intro {
  padding: 0.25rem 1rem 0.25rem 0.5rem;
  margin: 2rem 0;
  border-left: 0.25rem solid #0f2e53;
}
.Code {
  background: #f2f2f2;
  padding: 0.125rem;
  border-radius: 0.125rem;
  font-family: monospace;
}
.Emoji {
  padding-right: 0.5rem;
} /* EDITABLE BLOCK*/
.Block {
  margin: 0.25rem 0;
  padding: 0.5rem;
  background: #fafafa;
  border-radius: 0.25rem;
}
.Block:hover {
  background: #f8f8f8;
  outline-color: #f5f6fb;
}
.Block:focus {
  background: #f5f6fb;
} /* SELECT MENU */
.SelectMenu {
  position: absolute;
  width: 6.875rem;
  height: 9rem;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.SelectMenu .Items {
  background: #fafafa;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  border-radius: 0.5rem;
}
.SelectMenu .Items div[role=\"button\"] {
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid f5f6fb;
}
.SelectMenu .Items div[role=\"button\"]:hover {
  background: #f5f6fb;
  cursor: pointer;
}
.SelectMenu .Items div[role=\"button\"]:last-of-type {
  border-bottom: none;
}
.SelectMenu .Items div[role=\"button\"]:hover,
.SelectMenu .Items div[role=\"button\"]:focus {
  background: #f5f6fb;
  cursor: pointer;
}
.SelectMenu .Items div[role=\"button\"]:first-of-type:hover,
.SelectMenu .Items div[role=\"button\"]:first-of-type:focus {
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
.SelectMenu .Items div[role=\"button\"]:last-of-type:hover,
.SelectMenu .Items div[role=\"button\"]:last-of-type:focus {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.Selected {
  background: #f5f6fb;
  border-radius: 0.5rem;
}

#sticky {
  position: sticky;
  display: inline-block;
  vertical-align: top;
  max-height: 100vh;
  overflow-y: auto;
  width: 200px;
  top: 0;
  bottom: 0;
  padding: 30px;
}
